import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import * as yup from "yup";
import Select from "react-select";
import toast from "react-hot-toast";

import Admin from "../../shared/components/Admin";
import { Activity } from "../../shared/@types/activity";
import {
  usePostActivity,
  usePutActivity,
  useGetActivity,
  useDeleteActivityParticipant,
  useGetActivityParticipants,
} from "../../store/hooks/activityHooks";
// import {
//   useGetParticipants
// } from "../../store/hooks/participantHooks";
import { useGetProjects, useProjects } from "../../store/hooks/projectHooks";
import styles from "../styles.module.scss";
import { stateProps } from "../../shared/@types/general";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";

import { REQUIRED } from "../../utils/formUtils";
import { modelActivities, typeActivities } from "../../shared/constants";
import { Project } from "../../shared/@types/project";
import ParticipantForm from "./Form/ParticipantForm";
import ModalDelete from "../../shared/components/ModalDelete";
import ActivityParticipantList from "./activityParticipantList";
import { usePermission } from "../../store/hooks/permissionHooks";

const activitySchema = yup.object().shape({
  subject: yup.string().required(REQUIRED),
});

// "idProject": 1,
// "initialDate": "2023-10-02",
// "finalDate": "2023-10-02",
// "subject": "Teste de cadastro",
// "record": "Teste de encaminhamento",
// "model": "SIMPLE",
// "typeActivity": "INTERNAL",
// "totalMale": 0,
// "totalFemale": 0,
// "totalOthers": 0

interface ParamTypes {
  id: string;
}

const ActivityManagement = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getCurrentItem = useGetCurrentItem();
  const { id } = useParams<ParamTypes>();
  const postActivity = usePostActivity();
  const putActivity = usePutActivity();
  const getActivity = useGetActivity();
  const getProjects = useGetProjects();
  const projectsData = useProjects();
  const deleteActivityParticipant = useDeleteActivityParticipant();
  const getActivityParticipants = useGetActivityParticipants();
  // const getParticipants = useGetParticipants();

  const [redirect, setRedirect] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const [activityParticipants, setActivityParticipants] = useState<any>([]);

  const [saveAllowed, setSaveAllowed] = useState(false);
  const [saveCheckinAllowed, setSaveCheckinAllowed] = useState(false);
  const [deleteCheckinAllowed, setDeleteCheckinAllowed] = useState(false);
  const [listCheckinAllowed, setListCheckinAllowed] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "activity_index_post",
      "activity_index_put",
      "activity_index_get_one",
      "checkin_index_post",
      "checkin_index_delete",
      "checkin_index_get",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (id && access.includes("checkin_index_post"))
        setSaveCheckinAllowed(true);
      if (id && access.includes("checkin_index_delete"))
        setDeleteCheckinAllowed(true);
      if (id && access.includes("checkin_index_get"))
        setListCheckinAllowed(true);

      if (id && access.includes("activity_index_get_one")) setSaveAllowed(true);
      if (!id && access.includes("activity_index_post")) setSaveAllowed(true);
      if (
        id &&
        access.includes("activity_index_get_one") &&
        access.includes("activity_index_put")
      )
        setSaveAllowed(true);
      if (
        id &&
        access.includes("activity_index_get_one") &&
        !access.includes("activity_index_put")
      )
        setSaveAllowed(false);

      if (!access.includes("activity_index_get_one") && id) {
        toast.error(
          "Você não tem acesso para visualizar/editar uma atividade."
        );
        setRedirect(true);
      }
      if (!access.includes("activity_index_post") && !id) {
        toast.error("Você não tem acesso para cadastrar uma atividade.");

        setRedirect(true);
      }
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const getCurrentActivityParticipants = async () => {
    const resultActivityParticipants: any = await getActivityParticipants(
      access_token,
      id
    );
    if (resultActivityParticipants) {
      setActivityParticipants(resultActivityParticipants.data);
    }
  };

  const handleSubmit = (values: Activity) => {
    let result: any;

    if (
      values.model === "SIMPLE" &&
      values.typeActivity &&
      values.typeActivity !== "INTERNAL" &&
      values.totalMale === 0 &&
      values.totalFemale === 0 &&
      values.totalOthers === 0
    ) {
      toast.error(
        "Indique o número de homens, mulheres e outros gêneros participantes."
      );
      return;
    }

    if (id) {
      result = putActivity(access_token, values, id);
    } else {
      result = postActivity(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando atividade...",
      success: (res: any) => {
        if (!id && res.idActivity) {
          toast.success("Atividade cadastrada!");

          return (
            <Redirect
              to={{
                pathname: `/atividade/${res.idActivity}`,
              }}
            />
          );
        }

        return "Atividade cadastrada!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 ||
            err.response.status === 403 ||
            err.response.status === 422)
        ) {
          setRedirect(true);
          return err.response.data.error;
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      idProject: "",
      initialDate: "",
      finalDate: "",
      subject: "",
      record: "",
      model: "",
      typeActivity: "",
      typeActivityOther: "",
      totalMale: 0,
      totalFemale: 0,
      totalOthers: 0,
      lock: false,
    },
    validationSchema: activitySchema,
    onSubmit: (values: Activity) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    getProjects(access_token, 1, true);
    getCurrentActivityParticipants();
    getCurrentItem("activities");

    const getInfo = async () => {
      if (id) {
        const result: any = await getActivity(access_token, id);
        if (result) {
          setFieldValue("idProject", result.idProject);
          setFieldValue("initialDate", result.initialDate);
          setFieldValue("finalDate", result.finalDate);
          setFieldValue("subject", result.subject);
          setFieldValue("record", result.record);
          setFieldValue("model", result.model);
          setFieldValue("typeActivity", result.typeActivity);
          setFieldValue("typeActivityOther", result.typeActivityOther);
          setFieldValue("totalMale", result.totalMale);
          setFieldValue("totalFemale", result.totalFemale);
          setFieldValue("totalOthers", result.totalOthers);
        }
      }
    };

    getInfo();
    //eslint-disable-next-line
  }, [
    id,
    getActivity,
    setFieldValue,
    access_token,
    getCurrentItem,
    getProjects,
  ]);

  const handleModalDelete = (id?: string) => {
    setModalDelete(!modalDelete);
    if (id) setCurrentId(id);
  };

  const handleDelete = () => {
    const result = deleteActivityParticipant(access_token, currentId);

    toast.promise(result, {
      loading: "Excluindo participante da atividade...",
      success: () => {
        getCurrentActivityParticipants();
        setModalDelete(!modalDelete);
        setCurrentId("");
        return "Participante excluído da atividade.";
      },
      error: (error) => error.message,
    });
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/atividades`,
        }}
      />
    );
  }

  return (
    <Admin>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>{id ? "Alterar Atividade" : "Cadastrar Atividade"}</h3>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Assunto</Form.Label>
                <Form.Control
                  id="subject"
                  name="subject"
                  type="text"
                  onChange={handleChange}
                  value={values.subject}
                />
                {touched.subject && errors.subject && (
                  <Form.Text className={styles.textError}>
                    {errors.subject}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <Form.Label>Data inicial</Form.Label>
                <Form.Control
                  id="initialDate"
                  name="initialDate"
                  type="date"
                  onChange={handleChange}
                  value={values.initialDate}
                />
                {touched.initialDate && errors.initialDate && (
                  <Form.Text className={styles.textError}>
                    {errors.initialDate}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group>
                <Form.Label>Data final</Form.Label>
                <Form.Control
                  id="finalDate"
                  name="finalDate"
                  type="date"
                  onChange={handleChange}
                  value={values.finalDate}
                />
                {touched.finalDate && errors.finalDate && (
                  <Form.Text className={styles.textError}>
                    {errors.finalDate}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Label htmlFor="idProject">Projeto</Form.Label>
              <Select
                placeholder="Selecione uma opção"
                noOptionsMessage={() => "Não encontrado"}
                name="idProject"
                onChange={(option: any) =>
                  setFieldValue("idProject", option.value ? option.value : "")
                }
                value={
                  projectsData &&
                  projectsData.projects &&
                  projectsData.projects
                    .map((project: Project) => ({
                      label: project.name,
                      value: project.idProject,
                    }))
                    .find(
                      (option: OptionValue) => option.value === values.idProject
                    )
                }
                options={
                  projectsData &&
                  projectsData.projects &&
                  projectsData.projects.map((project: Project) => ({
                    label: project.name,
                    value: project.idProject,
                  }))
                }
              />
            </Col>

            <Col md={4}>
              <Form.Label htmlFor="typeActivity">Tipo de atividade</Form.Label>
              <Form.Select
                name="typeActivity"
                id="typeActivity"
                value={values.typeActivity}
                onChange={handleChange}
                style={{ margin: 0 }}
              >
                <option value="">Escolha sua opção</option>
                {typeActivities.map((item, index) => (
                  <option key={index} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Form.Select>
              {touched.typeActivity && errors.typeActivity && (
                <Form.Text className={styles.textError}>
                  {errors.typeActivity}
                </Form.Text>
              )}
            </Col>

            {values.typeActivity === "OTHER" && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Outro tipo de atividade</Form.Label>
                  <Form.Control
                    id="typeActivityOther"
                    name="typeActivityOther"
                    type="text"
                    onChange={handleChange}
                    value={values.typeActivityOther}
                  />
                  {touched.typeActivityOther && errors.typeActivityOther && (
                    <Form.Text className={styles.textError}>
                      {errors.typeActivityOther}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            )}

            <Col md={4}>
              <Form.Label htmlFor="model">Modelo de atividade</Form.Label>
              <Form.Select
                name="model"
                id="model"
                value={values.model}
                onChange={handleChange}
                style={{ margin: 0 }}
              >
                <option value="">Escolha sua opção</option>
                {modelActivities.map((item, index) => (
                  <option key={index} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Form.Select>
              {touched.model && errors.model && (
                <Form.Text className={styles.textError}>
                  {errors.model}
                </Form.Text>
              )}
            </Col>

            {values.model === "SIMPLE" && (
              <>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Total de homens</Form.Label>
                    <Form.Control
                      id="totalMale"
                      name="totalMale"
                      type="number"
                      onChange={handleChange}
                      value={values.totalMale}
                    />
                    {touched.totalMale && errors.totalMale && (
                      <Form.Text className={styles.textError}>
                        {errors.totalMale}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Total de mulheres</Form.Label>
                    <Form.Control
                      id="totalFemale"
                      name="totalFemale"
                      type="number"
                      onChange={handleChange}
                      value={values.totalFemale}
                    />
                    {touched.totalFemale && errors.totalFemale && (
                      <Form.Text className={styles.textError}>
                        {errors.totalFemale}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Total de outros gêneros</Form.Label>
                    <Form.Control
                      id="totalOthers"
                      name="totalOthers"
                      type="number"
                      onChange={handleChange}
                      value={values.totalOthers}
                    />
                    {touched.totalOthers && errors.totalOthers && (
                      <Form.Text className={styles.textError}>
                        {errors.totalOthers}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </>
            )}

            <Col md={12}>
              <Form.Group>
                <Form.Label>Encaminhamento</Form.Label>
                <Form.Control
                  as="textarea"
                  id="record"
                  name="record"
                  type="text"
                  onChange={handleChange}
                  value={values.record}
                />
                {touched.record && errors.record && (
                  <Form.Text className={styles.textError}>
                    {errors.record}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            {saveAllowed && values.lock !== "1" && (
              <Col md={12} className="button-save">
                <Form.Group>
                  <Button type="submit">SALVAR</Button>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>
      </FormikProvider>
      <hr style={{ marginTop: 30, marginBottom: 50 }} />
      {id && values.model === "FULL" && (
        <>
          <ParticipantForm
            idCurrentActivity={id}
            getCurrentActivityParticipants={getCurrentActivityParticipants}
            saveCheckinAllowed={saveCheckinAllowed}
          />
          {listCheckinAllowed && (
            <ActivityParticipantList
              activityParticipants={activityParticipants}
              handleModalDelete={handleModalDelete}
              deleteCheckinAllowed={deleteCheckinAllowed}
            />
          )}
        </>
      )}
      {modalDelete && (
        <ModalDelete
          handleModal={handleModalDelete}
          handleDelete={handleDelete}
          modal={modalDelete}
        />
      )}
    </Admin>
  );
};

export default ActivityManagement;
