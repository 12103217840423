import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import * as yup from "yup";
import toast from "react-hot-toast";

import Admin from "../../shared/components/Admin";
import { User } from "../../shared/@types/user";
import { stateProps } from "../../shared/@types/general";
import {
  usePostUser,
  usePutUser,
  useGetUser,
  useGetPermissions,
} from "../../store/hooks/userHooks";
import styles from "./styles.module.scss";
import { useGetCurrentItem } from "../../store/hooks/sidebarHooks";
import { REQUIRED, EMAIL_VALIDATION } from "../../utils/formUtils";
import { usePermission } from "../../store/hooks/permissionHooks";
// import { permissionList } from "../permission/Form/constants";

const userSchema = yup.object().shape({
  name: yup.string().required(REQUIRED),
  email: yup.string().email(EMAIL_VALIDATION).required(REQUIRED),
});

interface ParamTypes {
  id: string;
}

const UserManagement = () => {
  const { access_token, user_data } = useSelector(
    (state: stateProps) => state.auth
  );
  const getCurrentItem = useGetCurrentItem();
  const { id } = useParams<ParamTypes>();
  const postUser = usePostUser();
  const putUser = usePutUser();
  const getUser = useGetUser();
  const getPermissions = useGetPermissions();

  const [redirectLogin, setRedirectLogin] = useState(false);
  const [redirectList, setRedirectList] = useState(false);

  const [currentPermissions, setCurrentPermissions] = useState<string[]>([]);
  const [permissionList, setPermissionList] = useState<any>([]);

  const [redirect, setRedirect] = useState(false);
  const [saveAllowed, setSaveAllowed] = useState(false);
  const [permissionListAllowed, setPermissionListAllowed] = useState(false);

  useEffect(() => {
    if (!user_data.scopes) return setRedirect(true);
    const scopes = [
      "users_index_post",
      "users_index_put",
      "users_permissions_get",
      "users_index_get_one",
      "users_resetPassword_put",
    ];
    const VerifyAccess = () => {
      const access = usePermission(scopes, user_data.scopes);

      if (id && access.includes("users_index_get_one")) setSaveAllowed(true);
      if (!id && access.includes("users_index_post")) setSaveAllowed(true);
      if (
        id &&
        access.includes("users_index_get_one") &&
        access.includes("users_permissions_get")
      )
        setSaveAllowed(true);
      if (
        id &&
        access.includes("users_index_get_one") &&
        !access.includes("users_permissions_get")
      )
        setSaveAllowed(false);

      if (!access.includes("users_index_get_one") && id) {
        toast.error("Você não tem acesso para visualizar/editar um usuário.");
        setRedirect(true);
      }
      if (!access.includes("users_index_post") && !id) {
        toast.error("Você não tem acesso para cadastrar um usuário.");
        setRedirect(true);
      }

      if (id && access.includes("users_permissions_get"))
        setPermissionListAllowed(true);

      if (id && access.includes("users_index_put")) setSaveAllowed(true);
    };

    VerifyAccess();
    //eslint-disable-next-line
  }, []);

  const handlePermission = (value: string) => {
    const hasPermission = currentPermissions.includes(value);
    if (!hasPermission)
      return setCurrentPermissions([...currentPermissions, value]);

    const newPermissions = currentPermissions.filter((item) => item !== value);
    return setCurrentPermissions(newPermissions);
  };

  const handleSubmit = (values: User) => {
    // try {
    let result: any;

    if (currentPermissions.length > 0) {
      values.scopes = currentPermissions.toString();
    } else {
      values.scopes = "";
    }

    if (id) {
      result = putUser(access_token, values, id);
    } else {
      result = postUser(access_token, values);
    }

    toast.promise(result, {
      loading: "Salvando usuário...",
      success: () => {
        setRedirectList(true);
        return "Usuário cadastrado!";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          setRedirectLogin(true);
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      scopes: "",
    },
    validationSchema: userSchema,
    onSubmit: (values: User) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, setFieldValue, errors, touched } = formik;

  useEffect(() => {
    getCurrentItem("users");
    const getInfo = async () => {
      if (id) {
        const result: any = await getUser(access_token, id);
        if (result) {
          setFieldValue("name", result.name);
          setFieldValue("email", result.email);
          setFieldValue("scopes", result.scopes);
        }
      }
    };

    getInfo();
  }, [id, getUser, setFieldValue, access_token, getCurrentItem]);

  useEffect(() => {
    if (values.scopes && values.scopes !== "") {
      const result = values.scopes.split(",");
      setCurrentPermissions(result);
    }
  }, [values.scopes]);

  useEffect(() => {
    getPermissions(access_token, 1).then((result) => {
      if (result) setPermissionList(result);
    });
  }, [access_token, getPermissions]);

  if (redirectLogin) {
    return (
      <Redirect
        to={{
          pathname: `/login`,
        }}
      />
    );
  }

  if (redirectList) {
    return (
      <Redirect
        to={{
          pathname: `/usuarios`,
        }}
      />
    );
  }

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/usuarios`,
        }}
      />
    );
  }

  const selectAllOptions = () => {
    const allPermissions: any = [];
    permissionList.map((permission: any) =>
      permission.scopes.map((item: any) => allPermissions.push(item.scope))
    );
    setCurrentPermissions(allPermissions);
  };

  const clearOptions = () => {
    setCurrentPermissions([]);
  };

  return (
    <Admin>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} className={styles.form}>
          <Row>
            <Col md={12}>
              <h3>{id ? "Alterar usuário" : "Cadastrar usuário"}</h3>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  value={values.name}
                />
                {touched.name && errors.name && (
                  <Form.Text className={styles.textError}>
                    {errors.name}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  id="email"
                  name="email"
                  type="e-mail"
                  onChange={handleChange}
                  value={values.email}
                />
                {touched.email && errors.email && (
                  <Form.Text className={styles.textError}>
                    {errors.email}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            {permissionListAllowed && (
              <>
                <Col md={12} className={styles.accessControl}>
                  <h4>Controle de acesso</h4>
                </Col>

                <Col md={12} className={styles.buttons}>
                  <Button onClick={selectAllOptions}>
                    Selecionar todas opções
                  </Button>
                  <Button onClick={clearOptions}>Desmarcar opções</Button>
                </Col>
              </>
            )}

            {permissionListAllowed &&
              permissionList &&
              permissionList.map((item: any, index: number) => {
                return (
                  <Row key={index}>
                    <Col md={12}>
                      <h4 className={styles.resource}>{item.resource}</h4>
                    </Col>
                    {item.scopes.map((scope: any) => (
                      <Col md={4}>
                        <label className={styles.containerLabel}>
                          {scope.name}
                          <input
                            className={styles.containerInput}
                            type="checkbox"
                            value={scope.scope}
                            onChange={(e) => handlePermission(e.target.value)}
                            checked={currentPermissions.includes(scope.scope)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                    ))}
                  </Row>
                );
              })}

            {saveAllowed && (
              <Col md={12} className="button-save">
                <Form.Group>
                  <Button type="submit" className={styles.buttonDefault}>
                    Salvar
                  </Button>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Form>
      </FormikProvider>
    </Admin>
  );
};

export default UserManagement;
