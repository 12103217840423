import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";

import { stateProps } from "../../shared/@types/general";
import { useGetProjects, useProjects } from "../../store/hooks/projectHooks";
import { useGetCashiers, useCashiers } from "../../store/hooks/cashierHooks";
import { useGetActivities, useActivities } from "../../store/hooks/activityHooks";
import {
  useExecuteProjectReport,
  useGenerateReport,
  useGenerateCashierReport,
  useGenerateActivityReport,
  useGetReports,
  useReports,
} from "../../store/hooks/reportHooks";
import Admin from "../../shared/components/Admin";
import styles from "../styles.module.scss";
import ReportList from "./List/ReportList";
import { Activity } from "../../shared/@types/activity";

const Index = () => {
  const { access_token } = useSelector((state: stateProps) => state.auth);
  const getProjects = useGetProjects();
  const getCashiers = useGetCashiers();
  const getActivities = useGetActivities();
  const getReportList = useGetReports();
  const generateProjectReport = useGenerateReport();
  const generateCashierReport = useGenerateCashierReport();
  const generateActivityReport = useGenerateActivityReport();

  const executeProjectReport = useExecuteProjectReport();
  const reportList = useReports();
  const projectsData = useProjects();
  const cashiersData = useCashiers();
  const activitiesData = useActivities();
  const [idProject, setIdProject] = useState("");
  const [idCashier, setIdCashier] = useState("");
  const [idActivity, setIdActivity] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [reportType, setReportType] = useState("");

  useEffect(() => {
    const getInfo = async () => {
      getProjects(access_token, 1, true);
      getCashiers(access_token, 1, true);
      getActivities(access_token, 1, true);
    };

    getInfo();
  }, [access_token, getProjects, getCashiers, getActivities]);

  useEffect(() => {
    if (idProject && idProject !== "") {
      getReportList(access_token, idProject);
    }
  }, [idProject, getReportList, access_token]);

  useEffect(() => {
    setIdCashier("");
    setIdProject("");
    setInitialDate("");
    setFinalDate("");
    getReportList(access_token, "");
    // eslint-disable-next-line
  }, [reportType]);

  const executeReport = async (res: any) => {
    if (res && res.idReport) {
      await executeProjectReport(access_token, res.idReport);
    }
    getReportList(access_token, idProject);
  };

  const generateReport = () => {
    let result;

    if (reportType === "project" || reportType === "project-resume") {
      result = generateProjectReport(access_token, idProject, reportType, initialDate, finalDate);
    } else if (reportType === "cashier") {
      result = generateCashierReport(
        access_token,
        idCashier,
        initialDate,
        finalDate
      );
    } else if (reportType === "activity") {
      result = generateActivityReport(
        access_token,
        idActivity
      );
    }

    toast.promise(result, {
      loading: "Gerando relatório...",
      success: (res: any) => {
        executeReport(res);
        return "Em breve seu relatório estará disponível na lista";
      },
      error: (err) => {
        if (
          err &&
          err.response &&
          err.response.status &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          return err.response.data.error;
        }
        return err.response.data.error;
      },
    });
  };

  let projectType =
    reportType === "project" || reportType === "project-resume" ? true : false;

  return (
    <>
      <Admin>
        <div className={styles.headerInfo}>
          <h2>Gerar novo relatório</h2>
        </div>
        <Form style={{ marginBottom: 20 }}>
          <Row>
            <Col md={6}>
              <Form.Label htmlFor="type">Tipo de relatório</Form.Label>
              <Form.Select
                name="reportType"
                id="reportType"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
                style={{ margin: 0 }}
              >
                <option value="">Escolha sua opção</option>
                <option value="activity">Atividade</option>
                <option value="cashier">Caixa</option>
                <option value="project">Projeto</option>
                <option value="project-resume">Projeto Resumido</option>
              </Form.Select>
            </Col>
          </Row>
          <Row className="d-flex align-items-end">
            {reportType === "cashier" && (
                <Col md={4}>
                  <Form.Label htmlFor="idCashier">Caixas</Form.Label>
                  <Select
                    placeholder="Selecione um caixa"
                    noOptionsMessage={() => "Não encontrado"}
                    name="idCashier"
                    onChange={(option: any) => setIdCashier(option.value)}
                    value={
                      cashiersData &&
                      cashiersData.cashiers &&
                      cashiersData.cashiers
                        .map((cashier: any) => ({
                          label: cashier.name,
                          value: cashier.idCashier,
                        }))
                        .find((option: any) => option.value === idCashier)
                    }
                    options={
                      cashiersData &&
                      cashiersData.cashiers &&
                      cashiersData.cashiers.map((cashier: any) => ({
                        label: cashier.name,
                        value: cashier.idCashier,
                      }))
                    }
                  />
                </Col>
            )}

            {projectType === true && (
              <Col md={4}>
                <Form.Label htmlFor="idProject">Projeto</Form.Label>
                <Select
                  placeholder="Selecione um projeto"
                  noOptionsMessage={() => "Não encontrado"}
                  name="idProject"
                  onChange={(option: any) => setIdProject(option.value)}
                  value={
                    projectsData &&
                    projectsData.projects &&
                    projectsData.projects
                      .map((project: any) => ({
                        label: project.name,
                        value: project.idProject,
                      }))
                      .find((option: any) => option.value === idProject)
                  }
                  options={
                    projectsData &&
                    projectsData.projects &&
                    projectsData.projects.map((project: any) => ({
                      label: project.name,
                      value: project.idProject,
                    }))
                  }
                />
              </Col>
            )}
            { reportType === "activity"  && (

                <Col md={4}>
                <Form.Label htmlFor="idActivity">Atividade</Form.Label>
                <Select
                  placeholder="Selecione uma atividade"
                  noOptionsMessage={() => "Não encontrado"}
                  name="idActivity"
                  onChange={(option: any) => setIdActivity(option.value)}
                  value={
                    activitiesData &&
                    activitiesData.activities &&
                    activitiesData.activities
                      .map((activity: Activity) => ({
                        label: activity.subject,
                        value: activity.idActivity,
                      }))
                      .find((option: any) => option.value === idProject)
                  }
                  options={
                    activitiesData &&
                    activitiesData.activities &&
                    activitiesData.activities.map((activity: any) => ({
                      label: activity.subject,
                      value: activity.idActivity,
                    }))
                  }
                />
              </Col>
              )
            }
            {
              reportType && reportType !== "" && reportType !== "activity" && (
                <>
                 <Col md={4}>
                  <Form.Group>
                    <Form.Label>Data inicial</Form.Label>
                    <Form.Control
                      id="initialDate"
                      name="initialDate"
                      type="date"
                      onChange={(e) => setInitialDate(e.target.value)}
                      value={initialDate}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Data final</Form.Label>
                    <Form.Control
                      id="finalDate"
                      name="finalDate"
                      type="date"
                      onChange={(e) => setFinalDate(e.target.value)}
                      value={finalDate}
                    />
                  </Form.Group>
                </Col>
                </>
              )
            }
          </Row>

          {/* {reportType === "cashier" ||
            (reportType === "project" && ( */}
          <Row>
            <Col md={12} className="button-save">
              <Form.Group>
                <Button onClick={generateReport} style={{ marginTop: 20 }}>
                  Novo relatório
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <hr style={{ marginBottom: 50 }} />
        {reportList && reportList.report && reportList.report.length > 0 && (
          <ReportList
            report={reportList.report}
            token={access_token}
            // handleCurrentItem={handleCurrentItem}
          />
        )}
      </Admin>
    </>
  );
};

export default Index;
